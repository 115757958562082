<script setup lang="ts">
import type { TileWithId } from '../base/Tiles.vue'
import flows from '~/flows'

const props = defineProps({
  flowIds: {
    type: Array<string>,
    required: true,
    validator: (values: Array<string>) => {
      return flows.filter(flow => values.includes(flow.id)).length === values.length
    },
  },
})

const emit = defineEmits<{
  (e: 'click', value: string): void
}>()

const tilesFlow = flows.filter(flow => props.flowIds.includes(flow.id))

function click(id: string) {
  emit('click', id)
}

const tiles: TileWithId[] = tilesFlow.map((tile) => {
  return {
    id: tile.id,
    title: tile.tileTitle,
    icon: tile.icon,
    disabled: tile.steps === undefined,
  }
})
</script>

<template>
  <BaseTiles :all-tiles="tiles" @click="click" />
</template>
